import React from "react";
import bg_ip_page from "../../assets/bg_ip_page.png";
import GroupSvg from "../../assets/GroupSvg.png";
import { DoubleQuotes, GRating } from "../../assets/icons";
import NewHomeLeadFormWithAPIForm from "../NewHomeLeadFormWithAPI";

const BetterModels = ({
  location,
  heading,
  description,
  ctaHeading,
  ctaDescription,
  modelTypes,
  g2RatingData,
  pageName,
}) => {
  return (
    <article className="relative px-5 py-16 overflow-hidden sm:py-20 md:py-40">
      <img
        src={GroupSvg}
        alt="bg gradient"
        className="hidden lg:block absolute -left-28 lg:-left-[440px] xl:-left-[475px] -top-[450px] h-full w-full"
      />

      <img
        src={bg_ip_page}
        alt="bg gradient"
        className="absolute inset-0 w-full h-full -z-10"
      />

      <section className="mx-auto max-w-7xl">
        <section className="z-10 flex flex-col justify-between text-white lg:flex-row gap-14 lg:gap-5 lg:items-center">
          <section className="flex-1 lg:max-w-md xl:max-w-none">
            <h2 className="text-2.5xl sm:text-3.5xl font-semibold lg:max-w-md">
              {heading}
            </h2>
            <p className="max-w-2xl mt-6 text-sm sm:text-base lg:max-w-md">
              {description}
            </p>
          </section>

          <section className="flex-1 space-y-7 ">
            {modelTypes?.map((item, index) => {
              const {
                section_feature_icon,
                section_feature_heading,
                section_feature_description,
              } = item || {};
              return (
                <section
                  className="flex flex-col items-center max-w-2xl gap-5 p-4 shadow-lg sm:flex-row lg:max-w-none build_card_container rounded-xl"
                  key={index}
                >
                  <img
                    src={section_feature_icon?.url}
                    width={0}
                    height={0}
                    alt={section_feature_icon?.alt || "Better Modal"}
                    loading="lazy"
                    className="flex items-center justify-center w-auto h-auto p-4 rounded-lg"
                  />
                  <section className="text-center sm:text-left">
                    <h6 className="text-xl font-semibold">
                      {section_feature_heading?.text}
                    </h6>
                    <p className="mt-1 text-sm sm:text-base line-clamp-2">
                      {section_feature_description?.text}
                    </p>
                  </section>
                </section>
              );
            })}
          </section>
        </section>

        <section className="flex flex-wrap justify-center lg:flex-nowrap gap-7 mt-28 lg:mt-20">
          {g2RatingData?.map((item, index) => {
            const { section_heading, section_description } = item || {};
            return (
              <section
                className="bg-gray-1700 flex-1 sm:flex-[0.5] lg:flex-1 shadow-lg space-y-4 lg:space-y-6 rounded-2xl p-6 lg:p-8"
                key={index}
              >
                <section className="flex items-center justify-between">
                  <GRating />

                  <DoubleQuotes />
                </section>

                <h4 className="text-base font-semibold sm:text-xl line-clamp-3">
                  {section_heading?.text}
                </h4>
                <p className="text-sm text-gray-600 sm:text-base">
                  {section_description?.text}
                </p>
              </section>
            );
          })}
        </section>

        <section className="w-full max-w-xl mx-auto text-center text-white mt-28">
          <h1 className="text-2.5xl sm:text-3.5xl font-semibold mb-5">
            {ctaHeading}
          </h1>

          <p className="mb-5 text-sm sm:text-base">{ctaDescription}</p>

          <section className="flex justify-center">
            <NewHomeLeadFormWithAPIForm
              pageName={pageName}
              location={location}
              btnText="Get started"
            />
          </section>
        </section>
      </section>
    </article>
  );
};

export default BetterModels;
