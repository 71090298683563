import { graphql } from "gatsby";
import React from "react";
import AnnotationTypes from "../components/AnnotationTypes";
import BetterModels from "../components/annotation_Pages/BetterModels";
import HeroSection from "../components/annotation_Pages/HeroSection";
import Layout from "../components/layout";
import RowsSection from "../components/sar_Pages/RowsSection";
import SEO from "../components/seo";
import "../styles/page/annotation_pages.scss";
import { pickSlice } from "../utilities/helpers";

const BenefitsCardSection = ({
  subHeading,
  heading,
  description,
  benefitPoints,
}) => {
  return (
    <div className="lg:max-w-sm max-w-full w-full text-left self-center flex lg:flex-col sm:flex-row flex-col lg:p-11 md:p-7 p-3 justify-between overflow-hidden rounded-2xl shadow-lg bg-white lg:mb-0 md:mb-6 mb-4 lg:h-96">
      <div className="flex flex-col space-y-3 sm:space-y-5 max-w-xs w-full">
        <h3 className="text-gray-1200 text-sm font-medium uppercase">
          {subHeading}
        </h3>

        <h4 className="text-purple-1000 text-xl font-semibold">{heading}</h4>

        <p className="max-w-md text-gray-600 text-base leading-5">
          {description}
        </p>
      </div>

      <div className="lg:mt-10 md:mt-0 mt-10">
        {benefitPoints?.map((item) => {
          const { list_item } = item || {};
          return (
            <div className="flex items-center mb-5">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.3819 8.27344H15.2827C15.0436 8.27344 14.8163 8.38828 14.6757 8.58516L10.9913 13.6945L9.32252 11.3789C9.18189 11.1844 8.95689 11.0672 8.71549 11.0672H7.61626C7.46392 11.0672 7.37486 11.2406 7.46392 11.3648L10.3842 15.4148C10.4532 15.5111 10.5442 15.5896 10.6495 15.6437C10.7549 15.6978 10.8717 15.7261 10.9901 15.7261C11.1086 15.7261 11.2253 15.6978 11.3307 15.6437C11.436 15.5896 11.527 15.5111 11.596 15.4148L16.5319 8.57109C16.6233 8.44688 16.5343 8.27344 16.3819 8.27344Z"
                  fill="url(#paint0_linear_4380_87428)"
                />
                <path
                  d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
                  fill="url(#paint1_linear_4380_87428)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_4380_87428"
                    x1="8.69022"
                    y1="-0.710522"
                    x2="25.2311"
                    y2="3.30825"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF80A6" />
                    <stop offset="1" stop-color="#5E44FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_4380_87428"
                    x1="8.69022"
                    y1="-0.710522"
                    x2="25.2311"
                    y2="3.30825"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF80A6" />
                    <stop offset="1" stop-color="#5E44FF" />
                  </linearGradient>
                </defs>
              </svg>

              <span className="ml-2 text-base">{list_item?.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SarPage = ({ location, data }) => {
  const _data = data?.prismicSarPage?.data || {};
  const [trusted_brands_list] = pickSlice(_data, "trusted_brands_list");
  const [format_support] = pickSlice(_data, "format_support");
  const encord_features = pickSlice(_data, "encord_features");
  const [annotation_types] = pickSlice(_data, "annotation_types");
  const [better_model] = pickSlice(_data, "better_model");
  const [g2_rating] = pickSlice(_data, "g2_rating");
  const [automation] = pickSlice(_data, "automation");
  return (
    <Layout mainClasses="">
      <article className="relative max-w-7xl mx-auto md:pt-44 pt-32 text-center px-5">
        <HeroSection
          trusted_brands_list={trusted_brands_list}
          location={location}
          description={_data?.page_description?.text}
          heading={_data?.page_heading?.html}
          imageSRC={_data?.hero_image?.url}
          imageWidth={_data?.hero_image?.dimensions?.width}
          imageHeight={_data?.hero_image?.dimensions?.height}
          videoSRC={_data?.hero_video?.url}
          imgAlt={_data?.hero_image?.alt}
          pageName="new sar"
          containerClasses="max-w-[49rem]"
        />

        <RowsSection
          isReverse={false}
          btnLink={format_support?.primary?.learn_more_link?.url}
          btnText="Learn more"
          description={format_support?.primary?.section_description?.text}
          heading={format_support?.primary?.section_heading?.text}
          imgAlt={format_support?.primary?.section_image?.alt || ""}
          imgSrc={format_support?.primary?.section_image?.url}
          imageWidth={format_support?.primary?.section_image?.dimensions?.width}
            imageHeight={
              format_support?.primary?.section_image?.dimensions?.height
            }
          subHeading={format_support?.primary?.section?.label}
        />

        <RowsSection
          isReverse={true}
          btnLink={automation?.primary?.learn_more_link?.url}
          btnText="Learn more"
          description={automation?.primary?.section_description?.text}
          heading={automation?.primary?.section_heading?.text}
          imgAlt={automation?.primary?.section_image?.alt || ""}
          imgSrc={automation?.items[0]?.section_image?.url}
          imageWidth={automation?.items[0]?.section_image?.dimensions?.width}
            imageHeight={
              automation?.items[0]?.section_image?.dimensions?.height
            }
          subHeading={automation?.primary?.section?.label}
        />
      </article>

      <article className="bg-[#FAFAFA] lg:py-28 md:py-10 py-24">
        <section className="flex lg:flex-row flex-col gap-x-4 max-w-7xl mx-auto justify-center p-5">
          {encord_features?.map((item, index) => {
            return (
              <BenefitsCardSection
                key={item?.id}
                benefitPoints={item?.items}
                description={item?.primary?.card_description?.text}
                heading={item?.primary?.card_heading?.text}
                subHeading={item?.primary?.card_label?.text}
              />
            );
          })}
        </section>
      </article>

      <article className="relative max-w-7xl mx-auto mt-6 p-5 text-center">
        <AnnotationTypes annotation_types={annotation_types} />
      </article>
      <BetterModels
        heading={better_model?.primary?.section_heading?.text}
        location={location}
        description={better_model?.primary?.section_description?.text}
        ctaHeading={better_model?.primary?.cta_heading?.text}
        ctaDescription={better_model?.primary?.cta_description?.text}
        modelTypes={better_model?.items}
        g2RatingData={g2_rating?.items}
        pageName="new sar"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicSarPage {
      data {
        body {
          ... on PrismicSarPageDataBodyAnnotationTypes {
            id
            items {
              label {
                html
                text
              }
              label_icon {
                alt
                url
              }
              label_slide_image {
                alt
                url
              }
              label_slide_video {
                url
              }
            }
            primary {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
            }
            slice_type
          }
          ... on PrismicSarPageDataBodyAutomation {
            id
            slice_type
            items {
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicSarPageDataBodyBetterModel {
            id
            slice_type
            items {
              section_feature_description {
                html
                text
              }
              section_feature_heading {
                html
                text
              }
              section_feature_icon {
                alt
                url
              }
            }
            primary {
              cta_description {
                html
                text
              }
              cta_heading {
                html
                text
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicSarPageDataBodyEncordFeatures {
            id
            slice_type
            items {
              list_item {
                html
                text
              }
            }
            primary {
              card_description {
                html
                text
              }
              card_heading {
                html
                text
              }
              card_label {
                html
                text
              }
            }
          }
          ... on PrismicSarPageDataBodyFormatSupport {
            id
            slice_type
            primary {
              learn_more_link {
                url
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_label {
                html
                text
              }
            }
          }
          ... on PrismicSarPageDataBodyG2Rating {
            id
            slice_type
            items {
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
          }
          ... on PrismicSarPageDataBodyTrustedBrandsList {
            id
            slice_type
            items {
              brand_logo {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
            primary {
              section_heading {
                html
                text
              }
            }
          }
        }
        hero_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
        meta_description {
          html
          text
        }
        meta_heading {
          html
          text
        }
        page_description {
          html
          text
        }
        page_heading {
          html
          text
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicSarPage?.data || {};

  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default SarPage;
