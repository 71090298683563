import React, { useEffect, useState } from "react";
import VideoComponent from "./VideoComponent";
import posterVideo from "../assets/videoPoster.png";
const Video = ({ src, posterImage, isMediaRounded }) => {
  return (
    <VideoComponent
      poster={posterVideo}
      data-aos="fade-up"
      data-aos-delay="150"
      key={src}
      className="h-full"
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};
const AnnotationTypes = ({
  annotation_types,
  labelClass = "text-gray-1200",
  activeAnnotationBg = "bg-purple-500",
  linkClass,
  linkIcon: LinkIcon,
  isMediaRounded = false,
  headingClass = "",
  pargraphClass = "",
  separatorClass = "",
  containerClass = "",
}) => {
  const [activeAnnotation, setActiveAnnotation] = useState(0);
  return (
    <section
      className={`flex flex-col justify-between gap-6 p-5 mx-auto my-6 bg-white md:flex-row md:p-11 rounded-3xl shadow-card `}
    >
      <div
        className={`flex-1 max-w-lg space-y-3 text-left sm:space-y-5 ${containerClass}`}
      >
        <h3 className={`${labelClass} text-sm font-medium uppercase`}>
          {annotation_types?.primary?.section_label?.text}
        </h3>
        <h4
          className={`text-purple-1000 sm:text-2.5xl text-xl font-semibold ${headingClass}`}
        >
          {annotation_types?.primary?.section_heading?.text}
        </h4>
        <p className={`text-base text-gray-600 ${pargraphClass}`}>
          {annotation_types?.primary?.section_description?.text}
        </p>
        {annotation_types?.primary?.learn_more_link?.url && (
          <a
            href={annotation_types?.primary?.learn_more_link?.url || ""}
            target="_blank"
            rel="noopener noreferrer"
            className={`${linkClass} font-bold flex items-center w-max`}
          >
            <span>Learn more</span>
            {LinkIcon && <LinkIcon />}
          </a>
        )}
        <div className={`border-t bg-gray-1400 ${separatorClass}`} />
        <div className="flex flex-wrap max-w-md gap-1.5 md:justify-start">
          {annotation_types?.items?.map(({ label_icon, label }, index) => {
            return (
              <div
                className={`flex items-center px-2.5 py-1 cursor-pointer ${
                  activeAnnotation !== index
                    ? "bg-gray-1600 text-purple-1000"
                    : `${activeAnnotationBg} text-white`
                } rounded-md`}
                key={index}
                onClick={() => setActiveAnnotation(index)}
              >
                <img
                  src={label_icon?.url}
                  width={0}
                  height={0}
                  alt={label_icon?.alt || "icon"}
                  loading="lazy"
                  style={{
                    filter:
                      activeAnnotation !== index ? "invert(0)" : "invert(100%)",
                  }}
                  className={`w-5 h-5 mr-2.5`}
                />
                <p className="text-xs sm:text-base">{label?.text}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex-1 mt-5  lg:mt-0">
        {annotation_types?.items[activeAnnotation]?.label_slide_video?.url ? (
          <div className=" justify-center lg:justify-end flex w-full h-auto max-w-2xl sm:h-128 duration-[1s] ease-in">
            <Video
              src={
                annotation_types?.items[activeAnnotation]?.label_slide_video
                  ?.url
              }
              isMediaRounded={isMediaRounded}
            />
          </div>
        ) : (
          <img
            src={
              annotation_types?.items[activeAnnotation]?.label_slide_image?.url
            }
            width={0}
            height={0}
            alt={
              annotation_types?.items[activeAnnotation]?.label_slide_image
                ?.alt || "supporting annotation"
            }
            loading="lazy"
            className={`w-full h-auto max-w-2xl ${
              isMediaRounded && "rounded-xl"
            }`}
          />
        )}
      </div>
    </section>
  );
};
export default AnnotationTypes;
